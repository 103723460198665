<template>
  <div
      style="display: flex; flex-direction: column; align-items: center; justify-content: center; background-color: white; min-height: 100vh; text-align: center;">
    <h3 class="font-weight-bold text-nowrap" style="font-size: 50px; color: #dc3545;margin-bottom: 20px">
      Error!
    </h3>
    <p style="font-weight: bold;font-size: 20px;" v-html="errorMsg">{{ errorMsg }}</p>
    <!-- <p>That didn't worked.<br>
      Do you want try again?</p> -->
    <b-col
        class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
        cols="3"
    >
      <FormButton
          isBlock
          isPill
          variant="main-green"
          class="font-weight-normal text-secondary mt-4 login-button"
          type="submit"
          @click.native="backFn"
      ><span class="text-white">{{ btnName }}</span></FormButton
      >
    </b-col>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import FormButton from "@/components/Form/FormButton";
// import VueCookies from 'vue-cookies'

export default {
  name: "ErrorPage",
  components: {
    FormButton,
  },
  data() {
    return {
      errorMsg: '',
      url: null,
      btnName: 'Back To Sign in'
    }
  },
  async mounted() {
    // const email = await this.getCookie("temp_email");
    // const password = await this.getCookie("temp_password");
    const token = await this.getCookie("oauth_access_token");
    const accountInvalid = await this.getCookie("account_not_found");
    const businessInvalid = await this.getCookie("no_active_package");
    const accountNotVerified = await this.getCookie("account_not_verified");
    const unauthorized = await this.getCookie("unauthorized");
    const inactive = await this.getCookie("inactive");
    if (unauthorized) {
      this.errorMsg = unauthorized.decodeURIComponent('%20', ' ');
      this.url = '/register'
      this.btnName = 'Back To Sign Up'
      return
    }
    if (inactive) {
      this.errorMsg = `Your account has been deactivated!<br/>
<br/>Please contact our support team for assistance`;
      this.url = '/register'
      this.btnName = 'Back To Sign Up'
      return
    }
    if (accountInvalid) {
      this.errorMsg = decodeURIComponent('%20', ' ');
      this.url = '/register'
      this.btnName = 'Back To Sign Up'
      return
    }

    if (businessInvalid) {
      // console.log("temp_email",email)
      // localStorage.setItem("temp_email",decodeURIComponent(email));
      // localStorage.setItem("temp_pass", password);
      this.setAccessToken(token)
      this.errorMsg = businessInvalid.replaceAll('%20', ' ')
      this.url = '/register/business'
      this.btnName = 'Back To Package Activation'
      return
    }

    if (accountNotVerified) {
      this.errorMsg = `Account not verified!<br/> Here are some possible reasons,<br>
1 - Incomplete Google Sign-In<br/>2 - Google Account Not Verified.<br>3 - Your Google account email address is set to be visible to other applications.
`;
      this.url = '/login'
    } else {
      this.errorMsg = `Oops! Something went wrong.<br/>Our team has been notified of this issue, and we're working hard to fix it.<br/>Please try again later, and if the problem persists, feel free to contact our support team for assistance`;
      this.url = '/login';
    }

  },
  methods: {
    ...mapActions({
      setAccessToken: "auth/setAccessToken",
    }),
    async backFn() {
      await this.deleteCookie("account_not_found");
      await this.deleteCookie("no_active_package");
      await this.deleteCookie("temp_email");
      await this.deleteCookie("temp_password");
      await this.deleteCookie("oauth_access_token");
      this.$router.push(this.url)

    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        console.log(parts)
        return parts
            .pop()
            .split(";")
            .shift()

      }
    },
    deleteCookie(name) {
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;";
    },
  },

}
</script>

<style scoped>
.container {
  min-height: 100vh;
  background: bisque;

}
</style>

<template>
  <InitLayout>
    <b-card
        no-body
        class="align-items-center justify-content-center bg-transparent border-0 rounded-0 px-1 px-lg-4 login-container-card"
        v-if="!paid && !partial"
    >
      <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100 mr-3"
          no-gutters
      >
      </b-row>
      <b-row
          class="d-flex flex-column align-items-center justify-content-center w-100 mr-3"
          no-gutters
      >
        <b-col cols="12" class="mt-2">
          <span class="font-primary font-weight-normal">
        How much would you like to pay?
      </span>
          <FormInput
              groupId="user-name-group"
              id="user-name-group-input"
              class="text-prime-gray rounded-2 m-0 p-0"
              label="Payment Change"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="number"
              v-model="changeAmount"
          ></FormInput>
        </b-col>
        <b-col cols="6" class="ml-1" style="margin-top: 2px">
          <b-button
              variant="main-green"
              class="d-flex flex-row align-items-center justify-content-center border-0"
              @click="paymentChange"
          >
            <span class="d-inline">Change</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100 "
          no-gutters
      >
        <b-col cols="12" class="ml-5">
          <b-button variant="main-green stripe-btn" :disabled="isLodging" v-if="!isLodging" @click="pay">
            <span class="d-inline" style="font-size: 20px;">Pay {{ invoiceCurrency }} {{ numberFormat(amount) }}</span>
          </b-button>
          <b-spinner v-if="isLodging"
                     class="ml-5"
                     variant="success"
                     type="grow"
                     label="Spinning"
          ></b-spinner>
        </b-col>
      </b-row>
    </b-card>
    <div
        v-if="paid"
    >
      <b-row>
      <span class="font-primary font-weight-normal text-main-green text-center ">
        <strong>You have already paid for this invoice.</strong>
      </span>
      </b-row>
      <span class="font-primary font-weight-normal">
        <strong>Payment History For {{ invoiceName }}</strong>
      </span>
      <br/>
      <span class="font-primary font-weight-normal">
        Total Invoice Amount :- {{ invoiceAmount.toFixed(2) }} {{ currency }}
      </span>
      <br/>
      <br/>
      <span class="font-primary font-weight-normal">
        Transactions:-
      </span>
      <br/>
      <div v-for="tr in transactions" :key="tr">
       <span class="font-primary font-weight-normal">
         Paid {{ tr.total_amount.toFixed(2) }} {{ currency }} {{
           new Date(tr.created_at).toLocaleDateString('en-US', {
             year: 'numeric',
             month: 'long',
             day: 'numeric',
             hour: 'numeric',
             minute: 'numeric',
             second: 'numeric'
           })
         }}
       </span>
      </div>
    </div>
    <div
        v-if="partial"
    >
      <b-row>
      <span class="font-primary font-weight-normal text-main-green text-center ">
        <strong>You have already made some payments for this invoice.</strong>
      </span>
      </b-row>
      <span class="font-primary font-weight-normal">
        Total Invoice Amount :- {{ invoiceAmount.toFixed(2) }} {{ currency }}
      </span>
      <br/>
      <span class="font-primary font-weight-normal">
        Total Paid Amount :- {{ partialAmount.toFixed(2) }} {{ currency }}
      </span>
      <br/>
      <span class="font-primary font-weight-normal">
        <strong>Amount to be paid :- {{ (invoiceAmount - partialAmount).toFixed(2) }} {{ currency }}</strong>
      </span>
      <br/>
      <br/>
      <b-col cols="12" class="mt-2">
        <span class="font-primary font-weight-normal">
        How much would you like to pay?
      </span>
        <FormInput
            groupId="user-name-group"
            id="user-name-group-input"
            class="text-prime-gray rounded-2 m-0 p-0"
            label="Change amount"
            isRequired
            :whiteBG="false"
            trim
            form="login-form"
            type="number"
            v-model="changeAmount"
        ></FormInput>
      </b-col>
      <b-col cols="12" style="text-align: right;margin-top: 5px">
        <b-button
            variant="main-green"
            class="align-items-center justify-content-center border-0"
            @click="paymentChange"
        >
          <span class="d-inline">Change</span>
        </b-button>
      </b-col>
      <b-col cols="12" style="margin-top: 10px">
        <b-button block variant="main-green stripe-btn" :disabled="isLodging" v-if="!isLodging" @click="pay">
          <span class="d-inline" style="font-size: 20px;">Pay {{ numberFormat(amount) }} {{ invoiceCurrency }}</span>
        </b-button>
        <b-spinner v-if="isLodging"
                   class="ml-5"
                   variant="success"
                   type="grow"
                   label="Spinning"
        ></b-spinner>
      </b-col>
      <br/>
      <br/>
      <br/>
      <span class="font-primary font-weight-normal">
        <strong>Payment History For {{ invoiceName }}</strong>
      </span>
      <br/>
      <span class="font-primary font-weight-normal">
        Transactions:-
      </span>
      <br/>
      <div v-for="tr in transactions" :key="tr">
       <span class="font-primary font-weight-normal">
         Paid {{ tr.total_amount.toFixed(2) }} {{ currency }} at {{
           new Date(tr.created_at).toLocaleDateString('en-US', {
             year: 'numeric',
             month: 'long',
             day: 'numeric',
             hour: 'numeric',
             minute: 'numeric',
             second: 'numeric'
           })
         }}.
       </span>
      </div>
    </div>
  </InitLayout>
</template>

<script>
import InitLayout from "@/layout/InitLayout";
import FormInput from "@/components/Form/FormInput";
import {formatNumbers} from "@/util/formatNumbers.js";
import {getInvoice, invoicePayment,} from "@/services/stripe.service";

export default {
  name: 'InvoicePay',
  data() {
    return {
      invoiceCurrency: null,
      isLodging: false,
      actual_value: null,
      changeAmount: null,
      amount: null,
      id: null,
      email: null,
      invoice_number: null,
      invoice_id: null,
      paid: false,
      transactions: null,
      invoiceName: null,
      invoiceAmount: null,
      currency: null,
      partial: null,
      inv: null,
      partialAmount: null
    }
  },
  components: {
    InitLayout,
    FormInput

  },
  watch: {
    // changeAmount(val){
    //   if(val){
    //     this.amount =val
    //   }else{
    //     this.amount = this.actual_value
    //   }
    // }
  },
  async mounted() {
    this.email = this.$route.query.email
    this.id = this.$route.params.id
    let {data} = await getInvoice(this.id);
    this.paid = data.paid;
    this.transactions = data.transactions;
    this.invoiceName = data.invoice;
    this.invoiceAmount = data.invoiceTotal;
    this.currency = data.currency;
    this.partial = data.partialPayment;
    this.inv = data.inv;
    this.partialAmount = data.partialAmount;

    this.actual_value = this.partial?this.inv.total_amount:data.invoice.total_amount;
    this.amount = this.partial ? this.invoiceAmount - this.partialAmount : data.invoice.total_amount;
    this.invoice_number = this.partial?this.inv.invoice_number:data.invoice.invoice_number;
    this.userId = this.partial?this.inv.created_by._id:data.invoice.created_by._id;
    this.invoiceCurrency = this.partial ? this.currency : data.invoice?.currency ?? 'NZD';
    this.invoice_id = this.partial?this.inv._id:data.invoice._id;
  },
  methods: {
    numberFormat(val) {
      return formatNumbers(val);
    },
    paymentChange() {
      this.amount = this.changeAmount
      this.changeAmount = null
    },
    async pay() {
      if (!this.amount) {
        let payloadNotify = {
          isToast: true,
          title: "Invoice Payment",
          content:
              "Please add the payment value",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
        return
      }
      this.isLodging = true
      const {data} = await invoicePayment({
        _id: this.invoice_id,
        InvoiceNumber: this.invoice_number,
        ReceiptEmail: this.email,
        Amount: this.amount,
        userId: this.userId,
        currency: this.invoiceCurrency
      })
      window.location.href = data.url
      this.isLodging = false
    }
  }

}
</script>

<style scoped>
.stripe-btn {
  padding: 10px 30px;
}
</style>

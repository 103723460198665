<template>
  <div
      style="display: flex; flex-direction: column; align-items: center; justify-content: center; background-color: white; min-height: 100vh; text-align: center;">
    <h3 class="text-primary font-weight-bold text-nowrap">
      Invoice Top Up Completed!
    </h3>
    <p>Please Wait</p>
    <div style="margin-top: 20px;">
      <b-spinner small label="Small Spinner"></b-spinner>
      <p>{{ stage }}</p>
    </div>
  </div>
</template>

<script>
import {IncreaseInvoiceLimit} from "@/services/invoice.service";

export default {
  name: "TopupSuccess",
  data() {
    return ({
      topup_type: null,
      stage: 'Increasing your invoice limit...',
    });
  },
  mounted() {
    this.completeTopup();
  },
  methods: {
    async completeTopup() {
      await IncreaseInvoiceLimit();
      this.$router.push("/invoices");
    }
  }
}
</script>

<style scoped>

</style>

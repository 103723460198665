<template>
  <div
      style="display: flex; flex-direction: column; align-items: center; justify-content: center; background-color: white; min-height: 100vh; text-align: center;">
    <h3 class="font-weight-bold text-nowrap" style="color: #dc3545;margin-bottom: 20px">
      Payment Failed!
    </h3>
    <p>That didn't worked.<br>
      Do you want try again?</p>
<!--    <div style="margin-top: 5px;width: 20vw">-->
<!--      <b-row class="d-flex flex-row flex-wrap align-items-center justify-content-center w-100"-->
<!--             no-gutters>-->
<!--        <b-col-->
<!--            class="d-flex flex-column align-items-center justify-content-center px-0 py-2"-->
<!--            cols="12"-->
<!--        >-->
<!--          <FormButton-->
<!--              variant="main-green"-->
<!--              isBlock-->
<!--              isPill-->
<!--              class="font-weight-normal text-secondary mt-3 mb-4 w-50"-->
<!--              type="submit"-->
<!--              @click.native="proceed"-->
<!--          >-->
<!--            <span class="text-white">Try Again</span>-->
<!--          </FormButton>-->
<!--        </b-col>-->
<!--      </b-row>-->
<!--      <b-row class="d-flex flex-row flex-wrap align-items-center justify-content-center w-100"-->
<!--             no-gutters>-->
<!--        <b-col-->
<!--            class="d-flex flex-column align-items-center justify-content-center px-0 py-2"-->
<!--            cols="12"-->
<!--        >-->
<!--          <FormButton-->
<!--              variant="main-green"-->
<!--              isBlock-->
<!--              isPill-->
<!--              class="font-weight-normal text-secondary mt-3 mb-4 w-50"-->
<!--              type="submit"-->
<!--              @click.native="goback"-->
<!--          >-->
<!--            <span class="text-white">Back to registration</span>-->
<!--          </FormButton>-->
<!--        </b-col>-->
<!--      </b-row>-->
<!--    </div>-->
  </div>
</template>

<script>
// import axios from "axios";


export default {
  name: "PaymentSuccess",
  components: {

  },
  data() {
    return ({
      stage: 'Finalizing Your Account'
    });
  },
  mounted() {
    this.completeRegistration();
  },
  methods: {
    async proceed() {
      // const url = await axios.post("/payments/", {package: localStorage.getItem('selected_package')});
      // window.location.replace(url.data)
    },
    goback(){
      // this.$router.push("/onboarding");
    }
  }
}
</script>

<style scoped>

</style>
